import Cookies from 'js-cookie'
const RememberMeTokenKey = 'eccomerc_rememberme_token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getRemToken() {
    const remToken = RememberMeTokenKey
    return Cookies.get(remToken)
}

export function setRemToken(token, cookieExpires) {
    const remToken = RememberMeTokenKey
    return Cookies.set(remToken, token, { expires: cookieExpires || -1 })
}

export function removeRemToken() {
    const remToken = RememberMeTokenKey
    return Cookies.remove(remToken)
}
