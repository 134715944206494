<template>
    <div class="hello">
        <div class="content">
            <div class="content_body">
                <div class="homelegance">HOMELEGANCE</div>
                <el-input v-model="loginForm.username" placeholder="Username" class="input" @keyup.enter="signin"
                    @input="changeUPvalue"></el-input>
                <el-form>
                    <el-input class="input" type="password" v-model="loginForm.password" placeholder="Password"
                        autocomplete="off" show-password @keyup.enter="signin" @input="changeUPvalue">
                    </el-input>
                    <el-input type="text" style="display: none" />
                </el-form>
                <div class="flex">
                    <div>
                        <el-checkbox class="savepassword" v-model="loginForm.remember_me" label="Remember Me"></el-checkbox>
                    </div>
                    <!-- <div><a href="#" class="interlinkage">I forget my password</a></div> -->
                </div>
                <div class="signin">
                    <el-button class="login" type="primary" @click="signin"
                        :loading="pageInfo.btn_loading">LOGIN</el-button>
                </div>
            </div>
            <div class="foot">
                <div class="flex" style="justify-content: center">
                    <div><img src="../assets/home_03.png" width="80" /></div>
                    <div class="copyright">
                        Copyright © 2023 Homelegance,Inc. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, onMounted, watch } from "vue";
import { ACCESS_TOKEN, LOGIN_IN_USERNAME } from "@/store/mutation-types";

import { useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import { login } from "@/api/login.js";
import { setRemToken, getRemToken, removeRemToken } from "@/utils/auth.js";
import { isNotEmpty } from "@/utils/util.js";

export default {
    name: "Login",
    setup() {
        const pageInfo = reactive({
            btn_loading: false
        })
        const loginForm = reactive({
            username: "",
            password: "",
            remember_me: false,
        });
        //remember me 自动回填是否修改过
        let isChangeRem = ref(false);

        const router = useRouter();

        onMounted(() => {
            let rememberMe_token = getRemToken()

            if (isNotEmpty(rememberMe_token)) {
                rememberMe_token = rememberMe_token.split("#;#");
                loginForm.username = rememberMe_token[0]
                loginForm.password = "********************";
                loginForm.remember_me = true;
            }
        })

        function changeUPvalue() {
            isChangeRem = true
            loginForm.remember_me = false
        }
        function signin() {
            pageInfo.btn_loading = true

            let params = {
                username: loginForm.username,
                password: loginForm.password,
                isRememberMe: loginForm.remember_me,
            };

            let rememberMe_token = getRemToken()
            if (isChangeRem.value == false && isNotEmpty(rememberMe_token)) {
                //console.log("rem")
                rememberMe_token = rememberMe_token.split("#;#");
                const username = rememberMe_token[0]
                const re_token = rememberMe_token[1];

                if (loginForm.remember_me == false) {
                    //清空remember me标记
                    removeRemToken()
                }
                params.reToken = re_token
            }

            login(params).then((res) => {
                if (res.success == true) {
                    ElMessage({
                        message: res.message,
                        type: 'success',
                        duration: 6 * 1000,
                    })

                    const token = res.token
                    const username = res.data.username

                    //写入token
                    sessionStorage.setItem(ACCESS_TOKEN, token)
                    sessionStorage.setItem(LOGIN_IN_USERNAME, username)

                    //remember me标记
                    const remeberme_token = res.data.remeberme_token
                    if (params.isRememberMe && isNotEmpty(remeberme_token)) {
                        let rm_token = username + "#;#" + remeberme_token
                        setRemToken(rm_token, 30 * 24 * 60 * 60 * 1000)
                    }

                    //跳转
                    router.push({
                        path: "/dashboard",
                    });
                } else {
                    ElMessageBox.confirm(res.message, {
                        confirmButtonText: "OK",
                        showCancelButton: false,
                        type: "error",
                    }).then(() => { }).catch(() => { });
                }
            }).catch((err) => console.log(err)).finally(() => {
                pageInfo.btn_loading = false
            });
        }

        return {
            pageInfo,
            loginForm,
            signin,
            changeUPvalue
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
    width: 100%;
    background-image: url("../assets/back-img.png");
    height: 100vh;
    overflow: hidden;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content {
    padding-top: 10%;
}

.input {
    margin: 10px 0;
}

.savetext {
    color: #9c9c9c;
    font-size: 13px;
    margin-left: 5px;
}

.homelegance {
    color: black;
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    margin: 8% 0;
    letter-spacing: 2px;
}

.content_body {
    width: 20%;
    margin: 30px auto;
    padding: 4% 3% 2% 3%;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
}

.copyright {
    font-size: 13px;
    color: #605e5d;
}

.interlinkage {
    text-decoration: none;
    color: #9c9c9c;
    font-size: 13px;
}

.savepassword {
    color: #9c9c9c;
    height: 0;
}

.signin {
    margin: 10px 0;
    display: flex;
    justify-content: center;
}

.login {
    width: 100%;
    margin-top: 8%;
    background-color: #d38753;
    border-color: #d38753;
}

.foot {
    width: 50%;
    margin: 14% auto;
}
</style>
